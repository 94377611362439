import convert from "xml-js"

const stage = process.env.LAMBDA_STAGE
const devStage = "dev"
const showAllTags = false
/**
 * newsmlToJs takes an newsML XML / XHTML string and returns a javascript object
 * @param {String} xmlStr
 * TODO: this function is no longer in use, delete me?!
 */
function newsmlToJs(xmlStr) {
  // do work here
  const newsDOM = new DOMParser().parseFromString(
    xmlStr,
    "application/xhtml+xml"
  )
  try {
    const preXML = newsDOM.querySelector("pre").outerHTML
    const xmlJS = convert.xml2js(preXML, { compact: false })
    const textNodes = xmlJS.elements[0].elements.filter((f) => {
      if (f.type === "element" && f.attributes.Href === "Link") {
        return true
      }

      if (f.type === "element" && f.attributes.Href === "QuoteRef") {
        const splitRic = f.elements[0].text.split(".")
        return splitRic[0].length > 0
      }

      if (f.type === "element") {
        return false
      }

      // its f.type == text? why not make it explicit in the code?
      return true
    })
    // .map(n => {
    //   if (n.type === "element") {
    //     if (n.attributes.Href === "QuoteRef") {
    //       const ric = n.elements[0].text
    //       // const splitRic = ric.split(".")
    //       return {
    //         type: "text",
    //         text: `<v-chip>${ric}</v-chip>`
    //       }
    //     }

    //     if (n.attributes.Href === "Link") {
    //       const link = n.elements[0].text
    //       return {
    //         type: "text",
    //         text: `<a href="${link}">${link}</a>`
    //       }
    //     }

    //     const originType = n.attributes.Href
    //     const text = n.elements[0].text
    //     return {
    //       type: "text",
    //       text: `<v-chip>${originType} ${text}</v-chip>`
    //     }
    //   }

    return textNodes
  } catch (error) {
    console.error("Error converting newsML to JS Object: ", error)
  }
}

function newsMLToNodes(xmlStr, selector = "pre") {
  const newsDOM = new DOMParser().parseFromString(
    xmlStr,
    "application/xhtml+xml"
  )
  // pre.textContent may be what you're looking for
  return newsDOM.querySelector(selector)
}

/**
 * parseNews takes an XML string and returns an array of Strings that
 * can be used to build a news story of p tags
 * @param {String} xmlStr xmlString of newsML
 */
function parseNews(xmlStr) {
  // strRegex looks or Non whitespace characters - returns -1 for empty strings
  const pre = newsMLToNodes(xmlStr, "pre")
  const nodes = Array.from(pre.childNodes)
    .filter((f) => {
      if (f.nodeName === "#text") {
        return true
      } else {
        if (f.nodeName === "Origin") {
          if (stage === devStage && showAllTags) {
            // show all of the tags if in development
            return true
          }

          if (f.attributes.Href.value === "QuoteRef") {
            const split = f.innerHTML.split(".")
            return split.length === 2 && split[0].length > 0
          } else {
            return true
          }
        }
        return false
      }
    })
    .map((m) => {
      if (m.nodeName === "#text") {
        return m.textContent
      } else {
        return {
          t: m.attributes.Href.value,
          v: m.innerHTML,
        }
      }
      // if (
      //   m.nodeName === "Origin" &&
      //   m.attributes["Href"] &&
      //   m.attributes["Href"]["value"] === "QuoteRef"
      // ) {
      //   return m.textContent.trim().split(".")[0]
      // } else {
      //
      //   return m.textContent
      // }
    })
  const newlines = nodes.reduce((outerAcc, node) => {
    if (typeof node === "string") {
      const split = node.split("\n")
      outerAcc = [...outerAcc, ...split]
    } else {
      outerAcc.push(node)
    }
    return outerAcc
  }, [])

  /**
   * Notes to understand what is going on in the developers console
   * pre = the raw response within <pre></pre> tags
   * nodes = array of the nodes within that pre response
   * newlines = results of nodes split by the newline character \n
   * paragraphArray = attempt to build an array where the elements are
   * separate paragraphs
   *
   * The question now... for the Origin elements that represent
   * either a...
   * QuoteRef
   * NewsSearch
   * StoryRef
   * Link
   * how do I build / insert the correct html? I guess just insert everything
   * as html? and then set each element of the array equal to v-html for the
   * paragraph tag? that could work.
   */

  const strRegex = /\S/

  const paragraphArray = newlines.reduce((innerAcc, t) => {
    if (typeof t === "string") {
      const numEmptySpace = t.search(strRegex)
      if (
        numEmptySpace === -1 ||
        t.includes("Eikon") ||
        t.includes("coverage:")
      ) {
        // empty line, return acc
        return innerAcc
      }
      if (numEmptySpace === 4) {
        /// this the beginning of a new paragraph
        const trimmed = t.trim()
        innerAcc.push(trimmed)
      } else {
        // this line is a continuation of a previous paragraph
        const trimmed = t.trim()
        if (trimmed[0] === "(") {
          // make this its own new line
          innerAcc.push(trimmed)
        } else {
          const last = innerAcc.pop()
          if (last) {
            innerAcc.push(`${last} ${trimmed}`)
          } else {
            innerAcc.push(trimmed)
          }
        }
      }
    }
    // create the html here ... chekc the QuoteRef isn't the last thing in the array?
    else if (t.t === "QuoteRef") {
      const last = innerAcc.pop()
      // innerAcc.push(
      //   `${last} <a href="/refid?id=${encodeURIComponent(
      //     t.v
      //   )}" style="font-weight: bold;">${t.v.split(".")[0]}</a>`
      // )
      innerAcc.push(
        `${last} <a href="/refid?id=${encodeURIComponent(
          t.v
        )}" class="companychip primary--text font-weight-bold v-chip v-chip--clickable v-chip--link theme--light v-size--small" style="background-color: rgb(255, 172, 49); border-color: rgb(255, 172, 49);">
          <span class="v-chip__content">
          ${t.v.split(".")[0]}
          </span>
          </a>`
      )
    } else if (t.t === "Link") {
      const last = innerAcc.pop()
      // innerAcc.push(`<a href="${t.v}" blank="#">${last}</a>`)
      innerAcc.push(
        `${last} <a href="${t.v}" target="_blank" rel="noreferrer">${t.v}</a>`
      )
    }
    // else {
    // const last = innerAcc.pop()
    // innerAcc.push(`${last}${t.t}:${t.v}`)
    // }
    return innerAcc
  }, [])

  // console.log(pre)
  // console.log(textNodes)
  // console.log(paragraphArray)
  return paragraphArray
}

export { parseNews, newsmlToJs, newsMLToNodes }
