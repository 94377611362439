import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { parseNews } from "~/utils/trkd";
import useBaseUtils from "~/functions/useBaseUtils";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
export default {
  __name: 'TrkdOldNewsCard',
  props: {
    news: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    idx: {
      type: String,
      "default": ""
    },
    vuexkey: {
      type: String,
      "default": "market"
    },
    feedId: {
      type: String,
      "default": null
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var show = ref(false);
    var isActive = ref(false);
    var clicked = ref(false);
    var _useNuxtApp = useNuxtApp(),
      $route = _useNuxtApp.$route,
      $router = _useNuxtApp.$router,
      $store = _useNuxtApp.$store,
      $vuetify = _useNuxtApp.$vuetify;
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      I18nFn = _useBaseUtils.I18nFn;
    var _useDateTimeFormatter = useDateTimeFormatters(),
      userEpochToOnlyTime = _useDateTimeFormatter.userEpochToOnlyTime,
      userEpochToLongDate = _useDateTimeFormatter.userEpochToLongDate;
    var formatDate = function formatDate(dateObj) {
      return "".concat(userEpochToOnlyTime.value.format(dateObj), " | ").concat(userEpochToLongDate.value.format(dateObj));
    };
    var newsId = computed(function () {
      return props.news.id;
    });
    var rawNews = computed(function () {
      try {
        var newsObj = $store.state.trkd[props.vuexkey + "News"][props.idx];
        if (props.feedId) {
          var resObj = $store.state.trkd.feedResultsObj[props.feedId] || {
            news: {}
          };
          newsObj = resObj.news[props.idx];
        }
        return newsObj ? newsObj.raw : false;
      } catch (e) {
        console.error("trkdoldnewscard error: ", e);
        return false;
      }
    });
    var newsStory = computed(function () {
      return rawNews.value ? parseNews(rawNews.value) : I18nFn("Loading News Story...");
    });
    var companies = computed(function () {
      var companyString = props.news.CO;
      if (companyString) {
        var re = /[^A-Za-z0-9]+/;
        return companyString.split(" ").filter(function (f) {
          var split = f.split(".");
          var first = split[0];
          return split.length === 2 && first.length > 0 && !re.test(first);
        }); // .map(m => {
        //   return m.split(".")[0]
        // })
      } else {
        return [];
      }
    });
    var showStory = function showStory() {
      try {
        clicked.value = true;
        if (props.news["new"]) {
          if (props.feedId) {
            // toggle the read status for the id based news story
            $store.commit("trkd/toggleNewsReadStatus", {
              id: props.feedId,
              hid: newsId.value,
              status: false
            });
          } else {
            $store.commit("trkd/toggleNewsReadStatus", {
              type: props.vuexkey,
              id: newsId.value
            });
          }
        }
        var currentState = show.value;
        var currentRoute = $route.path;
        var currentQuery = $route.query; // const parsed = this.newsStory
        // if (parsed !== "Loading News Story..." && stage === devStage) {
        //   const pre = newsMLToNodes(this.rawNews, "pre")
        //   const nodes = Array.from(pre.childNodes)
        //     .filter(f => {
        //       if (f.nodeName === "Origin" || f.nodeName === "#text") {
        //         return true
        //       } else {
        //         if (stage === devStage) {
        //           // FIXME: What is this node?
        //
        //         }
        //         return false
        //       }
        //     })
        //     .map(m => {
        //       if (m.nodeName === "#text") {
        //         return m.textContent
        //       } else {
        //         return {
        //           t: m.attributes["Href"]["value"],
        //           v: m.innerHTML
        //         }
        //       }
        //       // if (
        //       //   m.nodeName === "Origin" &&
        //       //   m.attributes["Href"] &&
        //       //   m.attributes["Href"]["value"] === "QuoteRef"
        //       // ) {
        //       //   return m.textContent.trim().split(".")[0]
        //       // } else {
        //       //
        //       //   return m.textContent
        //       // }
        //     })
        //   const newlines = nodes.reduce((outerAcc, node) => {
        //     if (typeof node === "string") {
        //       const split = node.split("\n")
        //       outerAcc = [...outerAcc, ...split]
        //     } else {
        //       outerAcc.push(node)
        //     }
        //     return outerAcc
        //   }, [])
        //   /**
        //    * Notes to understand what is going on in the developers console
        //    * pre = the raw response within <pre></pre> tags
        //    * nodes = array of the nodes within that pre response
        //    * newlines = results of nodes split by the newline character \n
        //    * paragraphArray = attempt to build an array where the elements are
        //    * separate paragraphs
        //    *
        //    * The question now... for the Origin elements that represent
        //    * either a...
        //    * QuoteRef
        //    * NewsSearch
        //    * StoryRef
        //    * Link
        //    * how do I build / insert the correct html? I guess just insert everything
        //    * as html? and then set each element of the array equal to v-html for the
        //    * paragraph tag? that could work.
        //    */
        //   const strRegex = /\S/
        //   // eslint-disable-next-line no-unused-vars
        //   const paragraphArray = newlines.reduce((innerAcc, t) => {
        //     if (typeof t === "string") {
        //       const numEmptySpace = t.search(strRegex)
        //       if (numEmptySpace === -1) {
        //         // empty line, return acc
        //         return innerAcc
        //       }
        //       //if (numEmptySpace === 4 || numEmptySpace === 1) {
        //       if (numEmptySpace === 4) {
        //         /// this the beginning of a new paragraph
        //         const trimmed = t.trim()
        //         innerAcc.push(trimmed)
        //       } else {
        //         // this line is a continuation of a previous paragraph
        //         const trimmed = t.trim()
        //         if (trimmed[0] === "(") {
        //           // make this its own new line
        //           innerAcc.push(trimmed)
        //         } else {
        //           const last = innerAcc.pop()
        //           if (last) {
        //             innerAcc.push(`${last} ${trimmed}`)
        //           } else {
        //             innerAcc.push(trimmed)
        //           }
        //         }
        //       }
        //     } else {
        //       // create the html here
        //       if (t.t === "QuoteRef") {
        //         const last = innerAcc.pop()
        //         innerAcc.push(
        //           `${last} <v-chip nuxt-link:to="/refid?id=${encodeURIComponent(
        //             t.v
        //           )}" class="mx-1 companychip primary--text font-weight-bold" color="#FFAC31" small>${
        //             t.v
        //           }</v-chip>`
        //         )
        //       } else if (t.t === "Link") {
        //         const last = innerAcc.pop()
        //         // innerAcc.push(`<a href="${t.v}" blank="#">${last}</a>`)
        //         innerAcc.push(
        //           `${last} <a href="${t.v}" target="_blank" rel="noreferrer">${t.v}</a>`
        //         )
        //       } else {
        //         // const last = innerAcc.pop()
        //         // innerAcc.push(`${last}${t.t}:${t.v}`)
        //       }
        //     }
        //     return innerAcc
        //   }, [])
        //
        // }
        if (currentState) {
          show.value = false;
          var newQueryParams = _objectSpread({}, currentQuery);
          if (newQueryParams.n) {
            delete newQueryParams.n;
            $router.replace({
              path: currentRoute,
              query: newQueryParams
            });
          }
        } else {
          show.value = true;
          $router.replace({
            path: currentRoute,
            query: _objectSpread(_objectSpread({}, currentQuery), {
              n: newsId.value
            })
          });
        }
      } catch (error) {
        console.error("Error clicking showStory() TrkdOldNewsCard: ", error);
      }
    };
    var closeStory = function closeStory() {
      show.value = false;
      try {
        $vuetify.goTo("#".concat(newsId.value), {
          container: ".newscontainer"
        });
      } catch (error) {
        console.error("Error .goto from TrkdOldNewsCard: ", error);
      }
      var currentRoute = $route.path;
      var currentQuery = $route.query;
      var newQueryParams = _objectSpread({}, currentQuery);
      if (newQueryParams.n) {
        delete newQueryParams.n;
        $router.replace({
          path: currentRoute,
          query: newQueryParams
        });
      }
    };
    var checkParagraph = function checkParagraph(p) {
      // check paragraph entries in parsed news array to determine if the
      // paragraph should be registered... will probably end up a long list
      // of rules
      // const remove = ["LIVE/", "see:"]
      if (p.includes("LIVE/") || p.includes("see:")) {
        // I could also search for 'LIVE/' to perform this check
        return false;
      }
      return "".concat(p[0]).concat(p[1]) !== "((";
    };
    return {
      __sfc: true,
      props: props,
      show: show,
      isActive: isActive,
      clicked: clicked,
      $route: $route,
      $router: $router,
      $store: $store,
      $vuetify: $vuetify,
      refCode: refCode,
      I18nFn: I18nFn,
      userEpochToOnlyTime: userEpochToOnlyTime,
      userEpochToLongDate: userEpochToLongDate,
      formatDate: formatDate,
      newsId: newsId,
      rawNews: rawNews,
      newsStory: newsStory,
      companies: companies,
      showStory: showStory,
      closeStory: closeStory,
      checkParagraph: checkParagraph
    };
  }
};